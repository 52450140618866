<template>
    <div>
        <b-row v-if="state === 'initializing' || state === 'loading'">
            <b-col class="text-center">
                <b-spinner large></b-spinner>
            </b-col>
        </b-row>

        <b-row class="mb-4" v-if="state !== 'initializing'">
            <b-col cols="8">
                <baseTables v-if="state === 'showDateSelection'" :totalsData="selectedData"/>
            </b-col>
            <b-col cols="4" v-if="state === 'show' || state === 'showDateSelection'">
                <b-card>
                    <b-row>
                        <b-col>
                            <label>Week ending</label>
                            <b-form-select v-model="timeSelected" :disabled="state === 'loadingData'" @change="changeWeekSelection()">
                                <b-form-select-option v-for="(item, index) in weekEndingLists" :key="index.key" :value="item">{{item.value}}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button block variant="primary" @click="doSubmitTimesheet()">
                                Submit</b-button>
                        </b-col>
                    </b-row>

                </b-card>

                <subtotalTables v-if="state === 'showDateSelection'" class="mt-3" :totalsData="selectedData.totals" />
                
            </b-col>
        </b-row>
    </div>
</template>

<script>
import baseTables from '@/components/submitTimeSheetBaseTables.vue'
import subtotalTables from '@/components/submitTimeSheetSubTotalTables.vue'
import { mapActions, mapMutations } from 'vuex'


export default {
  components:{
    baseTables,
    subtotalTables
  },
  data: () => ({
    state: 'initializing',
    timeSelected: null,
    weekEndingLists: [],
    selectedData: {totals: []},
    
    tableDataSubTotal: {
        dataSource: [],
        isLoading: true,
        tableColumns: [
            {
                label: 'Day',
                key: 'description',
                sortable: true,
                tdClass: ''
            },
            {
                label: 'Hours',
                key: 'normaltime',
                sortable: true,
                tdClass: ''
            },
            {
                label: 'Overtime',
                key: 'overtime',
                sortable: true,
                tdClass: ''
            }
        ]
    }
  }),
  created(){
    this.setBreadcrumb([
        {
            text: 'Submit Timesheet'
        },
        {
            text: 'Submit',
            active: true
        }
    ])

    this.getWeekEndings()
    .then((res) => {
        this.weekEndingLists = res.data
        this.state = 'show'
    })
    .catch(() => {
        this.$router.back()
    })
      
  },
  methods: {
    ...mapActions(['getWeekEndings', 'getForSubmission', 'submitTimesheet']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    changeWeekSelection(){
        this.state = 'loading'
        //load data
        //console.log(this.timeSelected.key.indexOf("T"))
        this.$store.commit('setGetForSubmissionRequest', this.timeSelected.key.substr(0,this.timeSelected.key.indexOf("T")))
        this.getForSubmission()
        .then((res) => {
            //console.log(res)
            this.selectedData = res.data
            this.state = 'showDateSelection'
        })
    },
    doSubmitTimesheet(){
        this.state = 'loading'
        this.$store.commit('setGetForSubmissionRequest', this.timeSelected.key.substr(0,this.timeSelected.key.indexOf("T")))
        this.submitTimesheet()
        .then((res) => {
            //console.log(res)
            this.selectedData = res.data
            this.selectedData= {totals: []}
            this.timeSelected = null
            this.state = 'show'
        })
    }
  },
  computed: {
  }
    
}
</script>