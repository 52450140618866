<template>
    <div>
        <b-card>
            <b-row>
                <b-col>
                    <h4>Callout Time</h4>
                </b-col>
            </b-row>
            <b-row v-if="calloutState === 'loading'">
                <b-col class="text-center">
                    <b-spinner large></b-spinner>
                </b-col>
            </b-row>
            <b-row v-if="calloutState === 'show'">
                <b-col>
                    <b-table striped hover :items="tableDataCalloutTime.dataSource" :fields="tableDataCalloutTime.tableColumns" 
                        :busy="tableDataCalloutTime.isLoading" small sort-icon-left>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="mt-3">
            <b-row>
                <b-col>
                    <h4>Overtime Time Worked</h4>
                </b-col>
            </b-row>
            <b-row v-if="overTimeState === 'loading'">
                <b-col class="text-center">
                    <b-spinner large></b-spinner>
                </b-col>
            </b-row>
            <b-row v-if="overTimeState === 'show'">
                <b-col>
                    <b-table striped hover :items="tableDataOvertime.dataSource" :fields="tableDataOvertime.tableColumns" 
                        :busy="tableDataOvertime.isLoading" small sort-icon-left>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="mt-3">
            <b-row>
                <b-col>
                    <h4>Other Time</h4>
                </b-col>
            </b-row>
            <b-row v-if="otherTime === 'loading'">
                <b-col class="text-center">
                    <b-spinner large></b-spinner>
                </b-col>
            </b-row>
            <b-row v-if="otherTime === 'show'">
                <b-col>
                    <b-table striped hover :items="tableDataOther.dataSource" :fields="tableDataOther.tableColumns" 
                        :busy="tableDataOther.isLoading" small sort-icon-left>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>


<script>
import { mapActions } from 'vuex'


export default {
    components:{
    },
    props: {
        totalsData: Object
    },
    data: () => ({
        calloutState: 'loading',
        overTimeState: 'loading',
        otherTime: 'loading',
        tableDataCalloutTime: {
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Client',
                    key: 'client',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Location',
                    key: 'location',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Day',
                    key: 'day',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Hours',
                    key: 'hours',
                    sortable: true,
                    tdClass: ''
                }
            ]
        },
        tableDataOvertime: {
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Client',
                    key: 'client',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Location',
                    key: 'location',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Day',
                    key: 'day',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Hours',
                    key: 'hours',
                    sortable: true,
                    tdClass: ''
                }
            ]
        },
        tableDataOther: {
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Day',
                    key: 'day',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Hours',
                    key: 'hours',
                    sortable: true,
                    tdClass: ''
                },
            ]
        }
    }),
    mounted(){
        //other time
        let otherTime = []
        this.totalsData.otherHours.forEach(otherElement => {
            let newElement = {
                description: otherElement.description,
                hours: otherElement.daylyHours[0].hours,
                day: this.returnFullDayName(otherElement.daylyHours[0].day)
            }
            otherTime.push(newElement)
        });
        this.tableDataOther.dataSource = otherTime
        this.tableDataOther.isLoading = false
        this.otherTime ='show'

        //overtime worked
        let overtime = []
        this.totalsData.overTimeHours.forEach(otherElement => {
            let newElement = {
                description: otherElement.ticketIdWhd !== null ? 'JC - ' + otherElement.ticketIdWhd : otherElement.description,
                client: otherElement.client !== null ? otherElement.client : 'N/A',
                location: otherElement.location !== null ? otherElement.location : 'N/A',
                hours: otherElement.daylyHours[0].hours,
                day: this.returnFullDayName(otherElement.daylyHours[0].day)
            }
            overtime.push(newElement)
        });

        
        this.tableDataOvertime.dataSource = overtime
        this.tableDataOvertime.isLoading = false
        this.overTimeState ='show'

        
        //normal worked
        let normalTime = []
        this.totalsData.normalHours.forEach(otherElement => {
            let newElement = {
                description: otherElement.ticketIdWhd !== null ? 'JC - ' + otherElement.ticketIdWhd : otherElement.description,
                client: otherElement.client !== null ? otherElement.client : 'N/A',
                location: otherElement.location !== null ? otherElement.location : 'N/A',
                hours: otherElement.daylyHours[0].hours,
                day: this.returnFullDayName(otherElement.daylyHours[0].day)
            }
            normalTime.push(newElement)
        });

        
        this.tableDataCalloutTime.dataSource = normalTime
        this.tableDataCalloutTime.isLoading = false
        this.calloutState ='show'
    },
    methods: {
        ...mapActions([]),
        returnFullDayName(day){
            switch (day) {
                case 'Mon':
                    return 'Monday'
                case 'Tue':
                    return 'Tuesday'
                case 'Wed':
                    return 'Wednesday'
                case 'Thu':
                    return 'Thursday'
                case 'Fri':
                    return 'Friday'
                case 'Sat':
                    return 'Saturday'
                case 'Sun':
                    return 'Sunday'
            }
        }
    },
    computed: {
    }
    
}
</script>