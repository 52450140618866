<template>
    <div>
        <b-card class="mt-3">
            <b-row>
                <b-col>
                    <h4>Subtotal Time</h4>
                </b-col>
            </b-row>
            <b-row v-if="state === 'loading'">
                <b-col class="text-center">
                    <b-spinner large></b-spinner>
                </b-col>
            </b-row>
            <b-row v-if="state === 'show'">
                <b-col>
                    <b-table striped hover :items="tableDataSubTotal.dataSource" :fields="tableDataSubTotal.tableColumns" 
                        :busy="tableDataSubTotal.isLoading" small sort-icon-left>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
    components:{
    },
    props: {
        totalsData: Array
    },
    data: () => ({
        state: 'loading',
        tableDataSubTotal: {
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Day',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Hours',
                    key: 'normaltime',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Overtime',
                    key: 'overtime',
                    sortable: true,
                    tdClass: ''
                }
            ]
        }
    }),
  mounted(){
    let totals = [
        {description: 'Monday', overtime: 0, normaltime: 0},
        {description: 'Tuesday', overtime: 0, normaltime: 0},
        {description: 'Wednesday', overtime: 0, normaltime: 0},
        {description: 'Thursday', overtime: 0, normaltime: 0},
        {description: 'Friday', overtime: 0, normaltime: 0},
        {description: 'Saturday', overtime: 0, normaltime: 0},
        {description: 'Sunday', overtime: 0, normaltime: 0},
    ]
    this.totalsData.forEach(totalItem => {
        totalItem.daylyHours.forEach(dailyItem => {
            if (dailyItem.hours !== null) {
                switch (dailyItem.day) {
                    case 'Mon':
                        if (totalItem.description === 'Subtotal Normal Time') totals[0].normaltime = dailyItem.hours
                        if (totalItem.description === 'Subtotal Overtime') totals[0].overtime = dailyItem.hours 
                        break;
                    case 'Tue':
                        if (totalItem.description === 'Subtotal Normal Time') totals[1].normaltime = dailyItem.hours
                        if (totalItem.description === 'Subtotal Overtime') totals[1].overtime = dailyItem.hours 
                        break;
                    case 'Wed':
                        if (totalItem.description === 'Subtotal Normal Time') totals[2].normaltime = dailyItem.hours
                        if (totalItem.description === 'Subtotal Overtime') totals[2].overtime = dailyItem.hours 
                        break;
                    case 'Thu':
                        if (totalItem.description === 'Subtotal Normal Time') totals[3].normaltime = dailyItem.hours
                        if (totalItem.description === 'Subtotal Overtime') totals[3].overtime = dailyItem.hours 
                        break;
                    case 'Fri':
                        if (totalItem.description === 'Subtotal Normal Time') totals[4].normaltime = dailyItem.hours
                        if (totalItem.description === 'Subtotal Overtime') totals[4].overtime = dailyItem.hours 
                        break;
                    case 'Sat':
                        if (totalItem.description === 'Subtotal Normal Time') totals[5].normaltime = dailyItem.hours
                        if (totalItem.description === 'Subtotal Overtime') totals[5].overtime = dailyItem.hours 
                        break;
                    case 'Sun':
                        if (totalItem.description === 'Subtotal Normal Time') totals[6].normaltime = dailyItem.hours
                        if (totalItem.description === 'Subtotal Overtime') totals[6].overtime = dailyItem.hours 
                        break;
                }
            }
        });
    });
    this.tableDataSubTotal.dataSource = totals
    this.tableDataSubTotal.isLoading = false

    this.state = 'show'
  },
  methods: {
    ...mapActions([]),
  },
  computed: {
  }
    
}
</script>